<template>
  <div>
    <v-breadcrumbs class="py-2" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <router-link :to="item.href" class="v-breadcrumbs__item">
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>
    <v-card outlined class="mt-1">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          {{ $t("Academics: ") + record.name }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text v-if="record._id">
        <v-row dense>
          <v-col cols="12" offset-sm="2">
            <div>{{ $t("Faculty") }}</div>
            <div class="text-h3">{{ record.faculty }}</div>
          </v-col>
          <v-col cols="12" offset-sm="2">
            <div>{{ $t("Departament") }}</div>
            <div class="text-h3">{{ record.departament }}</div>
          </v-col>
          <v-col cols="12" offset-sm="2">
            <div>{{ $t("Scopus ids") }}</div>
            <span v-for="ids in record.researchers_ids.scopus_ids" :key="ids" class="text-h3 mr-3">
              <router-link :to="'/scopus/id-' + ids">
                {{ ids }}
              </router-link>
            </span>
          </v-col>
          <v-col cols="12" offset-sm="2">
            <div>{{ $t("Publons ids") }}</div>
            <span v-for="ids in record.researchers_ids.publons_ids" :key="ids" class="text-h3 mr-3">
              <router-link :to="'/publons/id-' + ids">
                {{ ids }}
              </router-link>
            </span>
          </v-col>
          <v-col v-if="record.contact" cols="12" offset-sm="2">
            <div>{{ $t("Email") }}</div>
            <div v-for="email in record.contact.emails" :key="email.email" class="text-h3 mr-3">
              <v-icon v-if="email.primary" color="green">
                mdi-seal
              </v-icon>
              <span>
                {{ email.email }}
              </span>

              <v-icon v-if="email.validated" color="green">
                mdi-check
              </v-icon>
            </div>
          </v-col>
          <v-col v-if="record.contact" cols="12" offset-sm="2">
            <div>{{ $t("Phone") }}</div>
            <div v-for="phone in record.contact.phones" :key="phone.phone" class="text-h3 mr-3">
              <v-icon v-if="phone.mobile" color="green" size="26" class="mt-n2">
                mdi-cellphone
              </v-icon>
              <span>
                {{ phone.phone }}
              </span>

              <v-icon v-if="phone.validated" color="green" size="26" class="mt-n2">
                mdi-check
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-sheet
          v-for="(employ, ind) in record.employment_years"
          :key="'cat' + ind"
          elevation="3"
          class="pa-3 mb-3 mt-3"
        >
          <v-row dense>
            <v-col cols="3" sm="2" class="text-h3">
              {{ employ.year }}
            </v-col>
            <v-col cols="9" sm="10">
              <v-row dense>
                <v-col cols="12" sm="4" class="mt-1">
                  {{ employ.didactic_rank }}
                </v-col>
                <v-col cols="12" sm="4" class="mt-1">
                  {{ employ.employment_contract }}
                </v-col>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-model="employ.phd_supervisor"
                    class="mt-0 pt-0"
                    readonly
                    dense
                    hide-details
                    :label="$t('PHD supervisor')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
                <v-col cols="12">
                  <v-row v-for="(index, i) in employ.fundamental_fields" :key="'ind' + i" dense>
                    <v-col cols="12" sm="8">
                      {{ index.field }}
                    </v-col>
                    <v-col cols="12" sm="4">
                      {{ index.percent }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      id: "",
      record: {},
      loading: true,
      path: "",
      render: 1,
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Academics",
          disabled: false,
          href: "/academics"
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "AcademicsEmployee") {
        this.id = this.$route.params.pathMatch
        this.getRecords()
      }
    },
    getRecords() {
      axios
        .get("infuni_academics/" + this.id)
        .then(response => {
          this.record = response.data
          this.record.employment_years.sort((a, b) => {
            return b.year - a.year
          })
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
